var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("案件名")]),_c('th',[_vm._v("タスク名")]),_c('th',[_vm._v("優先度")]),_c('th',[_vm._v("状態")]),_c('th',[_vm._v("期限日")]),_c('th',[_vm._v("担当者")]),_c('th',[_vm._v("依頼者")]),(this.$route.path.match(/draft$/))?_c('th',[_vm._v(" 振分"),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"primary","icon":""},on:{"click":function($event){return _vm.changeAssign()}}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold-box-outline")])],1)],1):_vm._e()])]),(this.$route.path.match(/mytask$/))?_c('tbody',_vm._l((_vm.tasks),function(task,index){return _c('tr',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,style:({ 'background-color': _vm.getBackgroundColor(task.task_status) }),on:{"click":function($event){return _vm.showTask(task)}}},[_c('td',[_vm._v(_vm._s(task.category.name))]),_c('td',[_vm._v(_vm._s(task.task_name))]),_c('td',{class:_vm.SELECTPRI.find((priority) => priority.value == task.task_priority)
            ?.class},[_vm._v(" "+_vm._s(_vm.SELECTPRI.find((priority) => priority.value == task.task_priority) ?.label)+" ")]),_c('td',{class:_vm.SELECTSTA.find((status) => status.value == task.task_status)?.class},[_vm._v(" "+_vm._s(_vm.SELECTSTA.find((status) => status.value == task.task_status)?.label)+" ")]),_c('td',[_vm._v(_vm._s(task.task_date_end))]),_c('td',[_c('v-avatar',{staticClass:"mr-1 mb-1",attrs:{"size":"25px"}},[(!task.requestedUser.img)?_c('v-img',{attrs:{"src":require('@/assets/images/user.png')}}):(
              task.requestedUser.id == _vm.$store.state.loginInfo.id &&
              _vm.$store.state.picture
            )?_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + _vm.$store.state.picture}}):_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + task.requestedUser.img}})],1),_c('span',[_vm._v(_vm._s(task.requestedUser.name))])],1),_c('td',[_c('v-avatar',{staticClass:"mr-1 mb-1",attrs:{"size":"25px"}},[(!task.requesterUser.img)?_c('v-img',{attrs:{"src":require('@/assets/images/user.png')}}):(
              task.requesterUser.id == _vm.$store.state.loginInfo.id &&
              _vm.$store.state.picture
            )?_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + _vm.$store.state.picture}}):_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + task.requesterUser.img}})],1),(task.task_requester_user_id !== -1)?_c('span',[_vm._v(_vm._s(task.requesterUser.name))]):_c('span',[_vm._v(_vm._s(task.task_requester_user_name))])],1),(!task.task_assign_boolean)?_c('td',[(!_vm.$store.state.selectDraft.includes(task.task_id))?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectAssign(task.task_id)}}},[_c('v-icon',[_vm._v("mdi-checkbox-outline")])],1):_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectAssign(task.task_id)}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked")])],1)],1):_vm._e()])}),0):_c('tbody',_vm._l((_vm.tasks),function(task,index){return _c('tr',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,style:({ 'background-color': _vm.getBackgroundColor(task.task_status) }),on:{"click":function($event){return _vm.$router.push(`/main/task/update?taskId=${task.task_id}`)}}},[_c('td',[_vm._v(_vm._s(task.category.name))]),_c('td',[_vm._v(_vm._s(task.task_name))]),_c('td',{class:_vm.SELECTPRI.find((priority) => priority.value == task.task_priority)
            ?.class},[_vm._v(" "+_vm._s(_vm.SELECTPRI.find((priority) => priority.value == task.task_priority) ?.label)+" ")]),_c('td',{class:_vm.SELECTSTA.find((status) => status.value == task.task_status)?.class},[_vm._v(" "+_vm._s(_vm.SELECTSTA.find((status) => status.value == task.task_status)?.label)+" ")]),_c('td',[_vm._v(_vm._s(task.task_date_end))]),_c('td',[_c('v-avatar',{staticClass:"mr-1 mb-1",attrs:{"size":"25px"}},[(!task.requestedUser.img)?_c('v-img',{attrs:{"src":require('@/assets/images/user.png')}}):(
              task.requestedUser.id == _vm.$store.state.loginInfo.id &&
              _vm.$store.state.picture
            )?_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + _vm.$store.state.picture}}):_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + task.requestedUser.img}})],1),_c('span',[_vm._v(_vm._s(task.requestedUser.name))])],1),_c('td',[_c('v-avatar',{staticClass:"mr-1 mb-1",attrs:{"size":"25px"}},[(!task.requesterUser.img)?_c('v-img',{attrs:{"src":require('@/assets/images/user.png')}}):(
              task.requesterUser.id == _vm.$store.state.loginInfo.id &&
              _vm.$store.state.picture
            )?_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + _vm.$store.state.picture}}):_c('v-img',{attrs:{"src":_vm.VUE_APP_BACK_URL + '/storage/images/' + task.requesterUser.img}})],1),(task.task_requester_user_id !== -1)?_c('span',[_vm._v(_vm._s(task.requesterUser.name))]):_c('span',[_vm._v(_vm._s(task.task_requester_user_name))])],1),(!task.task_assign_boolean)?_c('td',[(!_vm.$store.state.selectDraft.includes(task.task_id))?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectAssign(task.task_id)}}},[_c('v-icon',[_vm._v("mdi-checkbox-outline")])],1):_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectAssign(task.task_id)}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked")])],1)],1):_vm._e()])}),0),_c('v-dialog',{attrs:{"value":_vm.task !== null},on:{"click:outside":_vm.closeDialog}},[_c('MyTaskDetail',{attrs:{"task":_vm.task}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }