<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>案件名</th>
        <th>タスク名</th>
        <th>優先度</th>
        <th>状態</th>
        <th>期限日</th>
        <!-- <th>タグ</th> -->
        <th>担当者</th>
        <th>依頼者</th>
        <th v-if="this.$route.path.match(/draft$/)">
          振分<v-btn
            :loading="isLoading"
            color="primary"
            icon
            @click="changeAssign()"
          >
            <v-icon>mdi-arrow-up-bold-box-outline</v-icon>
          </v-btn>
        </th>
      </tr>
    </thead>
    <tbody v-if="this.$route.path.match(/mytask$/)">
      <tr
        @click="showTask(task)"
        v-ripple
        v-for="(task, index) in tasks"
        :key="index"
        :style="{ 'background-color': getBackgroundColor(task.task_status) }"
      >
        <td>{{ task.category.name }}</td>
        <td>{{ task.task_name }}</td>
        <td
          :class="
            SELECTPRI.find((priority) => priority.value == task.task_priority)
              ?.class
          "
        >
          {{
            SELECTPRI.find((priority) => priority.value == task.task_priority)
              ?.label
          }}
        </td>
        <td
          :class="
            SELECTSTA.find((status) => status.value == task.task_status)?.class
          "
        >
          {{
            SELECTSTA.find((status) => status.value == task.task_status)?.label
          }}
        </td>
        <td>{{ task.task_date_end }}</td>
        <!-- <td>{{task.tag.name}}</td> -->
        <td>
          <v-avatar size="25px" class="mr-1 mb-1">
            <v-img
              v-if="!task.requestedUser.img"
              :src="require('@/assets/images/user.png')"
            ></v-img>
            <v-img
              v-else-if="
                task.requestedUser.id == $store.state.loginInfo.id &&
                $store.state.picture
              "
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + $store.state.picture
              "
            ></v-img>
            <v-img
              v-else
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + task.requestedUser.img
              "
            ></v-img>
          </v-avatar>
          <span>{{ task.requestedUser.name }}</span>
        </td>
        <td>
          <v-avatar size="25px" class="mr-1 mb-1">
            <v-img
              v-if="!task.requesterUser.img"
              :src="require('@/assets/images/user.png')"
            ></v-img>
            <v-img
              v-else-if="
                task.requesterUser.id == $store.state.loginInfo.id &&
                $store.state.picture
              "
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + $store.state.picture
              "
            ></v-img>
            <v-img
              v-else
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + task.requesterUser.img
              "
            ></v-img>
          </v-avatar>
          <span v-if="task.task_requester_user_id !== -1">{{
            task.requesterUser.name
          }}</span>
          <span v-else>{{ task.task_requester_user_name }}</span>
        </td>
        <td v-if="!task.task_assign_boolean">
          <v-btn
            @click.stop="selectAssign(task.task_id)"
            color="primary"
            icon
            v-if="!$store.state.selectDraft.includes(task.task_id)"
          >
            <v-icon>mdi-checkbox-outline</v-icon>
          </v-btn>
          <v-btn
            @click.stop="selectAssign(task.task_id)"
            color="primary"
            icon
            v-else
          >
            <v-icon>mdi-checkbox-marked</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        @click="$router.push(`/main/task/update?taskId=${task.task_id}`)"
        v-ripple
        v-for="(task, index) in tasks"
        :key="index"
        :style="{ 'background-color': getBackgroundColor(task.task_status) }"
      >
        <td>{{ task.category.name }}</td>
        <td>{{ task.task_name }}</td>
        <td
          :class="
            SELECTPRI.find((priority) => priority.value == task.task_priority)
              ?.class
          "
        >
          {{
            SELECTPRI.find((priority) => priority.value == task.task_priority)
              ?.label
          }}
        </td>
        <td
          :class="
            SELECTSTA.find((status) => status.value == task.task_status)?.class
          "
        >
          {{
            SELECTSTA.find((status) => status.value == task.task_status)?.label
          }}
        </td>
        <td>{{ task.task_date_end }}</td>
        <!-- <td>{{task.tag.name}}</td> -->
        <td>
          <v-avatar size="25px" class="mr-1 mb-1">
            <v-img
              v-if="!task.requestedUser.img"
              :src="require('@/assets/images/user.png')"
            ></v-img>
            <v-img
              v-else-if="
                task.requestedUser.id == $store.state.loginInfo.id &&
                $store.state.picture
              "
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + $store.state.picture
              "
            ></v-img>
            <v-img
              v-else
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + task.requestedUser.img
              "
            ></v-img>
          </v-avatar>
          <span>{{ task.requestedUser.name }}</span>
        </td>
        <td>
          <v-avatar size="25px" class="mr-1 mb-1">
            <v-img
              v-if="!task.requesterUser.img"
              :src="require('@/assets/images/user.png')"
            ></v-img>
            <v-img
              v-else-if="
                task.requesterUser.id == $store.state.loginInfo.id &&
                $store.state.picture
              "
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + $store.state.picture
              "
            ></v-img>
            <v-img
              v-else
              :src="
                VUE_APP_BACK_URL + '/storage/images/' + task.requesterUser.img
              "
            ></v-img>
          </v-avatar>
          <span v-if="task.task_requester_user_id !== -1">{{
            task.requesterUser.name
          }}</span>
          <span v-else>{{ task.task_requester_user_name }}</span>
        </td>
        <td v-if="!task.task_assign_boolean">
          <v-btn
            @click.stop="selectAssign(task.task_id)"
            color="primary"
            icon
            v-if="!$store.state.selectDraft.includes(task.task_id)"
          >
            <v-icon>mdi-checkbox-outline</v-icon>
          </v-btn>
          <v-btn
            @click.stop="selectAssign(task.task_id)"
            color="primary"
            icon
            v-else
          >
            <v-icon>mdi-checkbox-marked</v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>

    <v-dialog :value="task !== null" @click:outside="closeDialog">
      <MyTaskDetail :task="task" />
    </v-dialog>
  </v-simple-table>
</template>

<script>
import { SELECTPRI } from "@/plugins/const";
import { SELECTSTA } from "@/plugins/const";
import MyTaskDetail from "@/components/MyTaskDetail.vue";

export default {
  props: ["tasks"],
  components: {
    MyTaskDetail,
  },
  data() {
    return {
      SELECTPRI: SELECTPRI,
      SELECTSTA: SELECTSTA,
      selectedFile: "",
      result: false,
      VUE_APP_BACK_URL: process.env.VUE_APP_BACK_URL,
      info: [],
      task: null,
    };
  },
  methods: {
    getBackgroundColor(status) {
      if (status === 5) {
        return "#E0FFFF";
      }
    },
    selectAssign(selectInfo) {
      if (!this.info.includes(selectInfo)) {
        this.info.push(selectInfo);
      } else {
        this.info = this.info.filter((x) => x !== selectInfo);
      }
      this.$store.commit("setSelectDraft", this.info);
    },
    changeAssign() {
      this.$emit("updateAssign");
    },
    showTask(task) {
      this.task = task;
    },
    closeDialog() {
      this.task = null;
    },
  },
  mounted() {
    this.info = this.$store.state.selectDraft;
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-sheet.v-card {
    padding: 20px;
  }
  .v-dialog {
    max-width: 80%;
  }
  .v-input__slot {
    margin-bottom: 0px;
  }
  .v-input--selection-controls {
    margin-top: 0px;
  }
  .v-input--radio-group__input .red--text {
    .theme--light.v-label {
      color: red;
    }
  }
  .v-input--radio-group__input .orange--text {
    .theme--light.v-label {
      color: orange;
    }
  }
  .v-input--radio-group__input .light-green--text {
    .theme--light.v-label {
      color: yellowgreen;
    }
  }
  .v-input--radio-group__input .green--text {
    .theme--light.v-label {
      color: green;
    }
  }
  .v-input--radio-group__input .blue--text {
    .theme--light.v-label {
      color: blue;
    }
  }
  .v-input--radio-group__input .amber--text {
    .theme--light.v-label {
      color: #ffc107;
    }
  }
}
</style>