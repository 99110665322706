<template>
  <v-card>
    <v-card-title>編集（{{ task.task_name }}）</v-card-title>
    <Loading v-if="isLoading" />
    <p v-else-if="tag === null">通信エラー</p>
    <v-card-text class="px-4" v-else>
      <ul class="d-flex">
        <li><v-card-subtitle>タスク情報</v-card-subtitle></li>
        <v-spacer></v-spacer>
        <li>
          <v-card-actions>
            <v-btn
              :disabled="isLoading"
              :loading="Loading"
              @click="openModal"
              color="primary"
            >
              <span>完了</span>
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
        </li>
      </ul>
      <section>
        <div id="overlay" v-show="showContent" @click.self="closeModal">
          <div id="content">
            <v-card-subtitle>タスク完了情報</v-card-subtitle>
            <ul>
              <li class="mb-3" style="width: 180px">
                <v-text-field
                  :value="task.task_actual_hours"
                  @input="onCreateActualTime"
                  label="実際の時間"
                  placeholder="実際の時間（分）"
                  prepend-inner-icon="mdi-clock-time-nine"
                  outlined
                  dense
                  clearable
                  color="primary"
                ></v-text-field>
              </li>
              <li class="mb-3">
                <v-text-field
                  :value="task.task_url"
                  @input="onCreateTaskUrl"
                  label="納品先URL"
                  placeholder="納品先URL"
                  prepend-inner-icon="mdi-link-variant"
                  outlined
                  dense
                  color="primary"
                ></v-text-field>
              </li>
            </ul>
            <v-card-actions>
              <v-btn
                :disabled="isLoading"
                :loading="Loading"
                @click="closeModal"
              >
                <span>閉じる</span>
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isLoading"
                :loading="Loading"
                color="primary"
                @click="completeTask()"
              >
                <span>送信</span>
                <v-icon>mdi-circle-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </div>
        </div>
        <ul>
          <li class="mb-3">
            <v-text-field
              :value="task.task_name"
              @input="onUpdateName"
              label="タスク名"
              dense
              outlined
              color="primary"
            ></v-text-field>
            <p v-if="updateTaskNameError" class="error-text">
              {{ updateTaskNameError }}
            </p>
          </li>
          <li class="mb-3">
            <v-textarea
              :value="task.task_detail"
              @input="onUpdateDetail"
              label="詳細"
              dense
              outlined
              color="primary"
            ></v-textarea>
            <!-- <p v-if="updateTaskDetailError" class="error-text">
              {{ updateTaskDetailError }}
            </p> -->
          </li>
          <li class="mb-3">
            <v-textarea
              label="コメント"
              :value="task.task_comment"
              @input="onUpdateComment"
              dense
              outlined
              color="primary"
            ></v-textarea>
          </li>
        </ul>
        <ul class="d-flex align-center">
          <li style="width: 180px">
            <v-text-field
              :value="task.task_date_start"
              @click="startDateDialog = true"
              @click:clear="onChangeStart"
              label="開始日"
              placeholder="指定なし"
              prepend-inner-icon="mdi-calendar"
              color="primary"
              readonly
              outlined
              dense
              clearable
            ></v-text-field>
            <v-dialog
              :value="startDateDialog"
              @click:outside="startDateDialog = false"
            >
              <v-date-picker
                :value="task.task_date_start"
                @change="onUpdateStartDate"
                color="primary"
                locale="ja"
              ></v-date-picker>
            </v-dialog>
          </li>
          <li>~</li>
          <li class="mr-3" style="width: 180px">
            <v-text-field
              :value="task.task_date_end"
              @click="endDateDialog = true"
              @click:clear="onChangeEnd"
              label="期限日"
              placeholder="指定なし"
              prepend-inner-icon="mdi-calendar"
              color="primary"
              readonly
              outlined
              dense
              clearable
            ></v-text-field>
            <v-dialog
              :value="endDateDialog"
              @click:outside="endDateDialog = false"
            >
              <v-date-picker
                :value="task.task_date_end"
                @change="onUpdateEndDate"
                color="primary"
                locale="ja"
              ></v-date-picker>
            </v-dialog>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-text-field
              :value="task.task_predicted_hours"
              @input="onUpdateScheduledTime"
              label="予定時間(hours)"
              placeholder="予定時間を入力"
              prepend-inner-icon="mdi-clock-time-nine-outline"
              outlined
              dense
              clearable
              color="primary"
            ></v-text-field>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-text-field
              :value="task.task_actual_hours"
              @input="onCreateActualTime"
              label="実際の時間"
              placeholder="実際の時間（分）"
              prepend-inner-icon="mdi-clock-time-nine"
              outlined
              dense
              color="primary"
              readonly
            ></v-text-field>
          </li>
          <li class="mr-3 d-flex">
            <v-text-field
              :value="task.task_url"
              @input="onCreateTaskUrl"
              label="納品先URL"
              placeholder="納品先URL"
              prepend-inner-icon="mdi-link-variant"
              outlined
              dense
              color="primary"
              readonly
              class="mr-1"
            ></v-text-field>
            <v-btn
              fab
              :href="task.task_url"
              target="_blank"
              style="width: 30px; height: 30px; transform: translateY(20%);"
              elevation="0"
              ><v-icon>mdi-arrow-up-thin</v-icon></v-btn
            >
          </li>
        </ul>
      </section>
      <p v-if="endDateError" class="error-text">{{endDateError}}</p>
      <section>
        <ul class="d-flex my-3">
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="this.$store.state.categories"
              :value="task.task_category_id"
              @change="onUpdateCategory"
              label="案件名"
              item-value="category_id"
              item-text="category_name"
              prepend-inner-icon="mdi-shape"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
          <!-- <li class="mr-3" style="width: 180px">
            <v-select
              :value="task.task_tag_id"
              :items="formTags"
              @change="onUpdateTag"
              label="タグ"
              item-value="tag_id"
              item-text="tag_name"
              prepend-inner-icon="mdi-tag"
              outlined
              dense
              color="primary"
            ></v-select>
          </li> -->
          <li class="mr-3" style="width: 180px">
            <v-select
              :value="task.task_requested_user_id"
              :items="this.$store.state.users"
              @change="onUpdateRequested"
              label="担当者"
              item-value="id"
              item-text="name"
              prepend-inner-icon="mdi-account"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-select
              :value="task.task_requester_user_id"
              :items="formUserData"
              @change="onUpdateRequester"
              label="依頼者"
              item-value="id"
              item-text="name"
              prepend-inner-icon="mdi-account"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
          <li v-if="task.task_requester_user_id === -1" class="mr-3">
            <v-text-field
              label="依頼者記入"
              :value="task.task_requester_user_name"
              @input="onUpdateRequesterUser"
              dense
              outlined
              color="primary"
            ></v-text-field>
            <p v-if="requesterUserNameError" class="error-text">
              {{ requesterUserNameError }}
            </p>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="assign"
              :value="task.task_assign_boolean"
              @change="onChangeAssign"
              label="振分"
              item-value="value"
              item-text="label"
              prepend-inner-icon="mdi-clipboard-check"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
        </ul>
        <ul>
          <li class="d-flex align-center my-4">
            <p class="mr-2">状態&emsp;：</p>
            <v-radio-group
              :value="task.task_status"
              @change="onUpdateStatus"
              color="primary"
              :column="false"
            >
              <v-radio
                v-for="status in SELECTSTA"
                :value="status.value"
                :label="status.label"
                :key="status.value"
              ></v-radio>
            </v-radio-group>
          </li>
          <li class="d-flex align-center my-4">
            <p class="mr-2">優先度：</p>
            <v-radio-group
              :value="task.task_priority"
              @change="onUpdatePriority"
              color="primary"
              :column="false"
            >
              <v-radio
                v-for="priority in SELECTPRI"
                :value="priority.value"
                :label="priority.label"
                :key="priority.value"
              ></v-radio>
            </v-radio-group>
          </li>
        </ul>
      </section>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="isLoading"
        :loading="Loading"
        @click="deleltedTask()"
        color="error"
      >
        <span>削除</span>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="isLoading"
        :loading="Loading"
        @click="changeTask()"
        color="primary"
      >
        <span>登録</span>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import Loading from "@/components/Loading.vue";
import { myAxios } from "@/plugins/axios";
import { SELECTSTA } from "@/plugins/const";
import { SELECTPRI } from "@/plugins/const";
export default {
  props: ["task"],
  components: {
    Loading,
  },
  data() {
    return {
      showContent: false,
      SELECTSTA: SELECTSTA,
      SELECTPRI: SELECTPRI,
      startDateDialog: false,
      endDateDialog: false,
      endDateError: "",
      updateTaskNameError: "",
      updateTaskDetailError: "",
      requesterUserNameError: "",
      Loading: false,
      isLoading: false,
      assign: [
        {
          value: true,
          label: "本番",
        },
        {
          value: false,
          label: "下書き",
        },
      ],
    };
  },
  computed: {
    // formCategories() {
    //   return [
    //     {
    //       category_id: 0,
    //       category_name: "全て",
    //     },
    //   ].concat(this.$store.state.categories);
    // },
    // formTags() {
    //   return [
    //     {
    //       tag_id: 0,
    //       tag_name: "全て",
    //     },
    //   ].concat(this.$store.state.tags);
    // },
    formUserData() {
      return [
        {
          id: -1,
          name: "記入",
        },
      ].concat(this.$store.state.users);
    },
  },
  methods: {
    onUpdatePriority(id) {
      this.task.task_priority = id;
    },
    onUpdateStatus(id) {
      this.task.task_status = id;
    },
    onUpdateName(taskName) {
      this.task.task_name = taskName;
    },
    onUpdateDetail(taskDetail) {
      this.task.task_detail = taskDetail;
    },
    onUpdateComment(taskComment) {
      this.task.task_comment = taskComment;
    },
    onUpdateStartDate(startDate) {
      this.task.task_date_start = startDate;
      this.startDateDialog = false;
    },
    onChangeStart() {
      this.task.task_date_start = null;
      this.startDateDialog = false;
    },
    onUpdateEndDate(endDate) {
      this.task.task_date_end = endDate;
      this.endDateDialog = false;
    },
    onChangeEnd() {
      this.task.task_date_end = null;
      this.endDateDialog = false;
    },
    onUpdateScheduledTime(scheduledTime) {
      this.task.task_predicted_hours = scheduledTime;
    },
    onUpdateActualTime(actualTime) {
      this.task.task_actual_hours = actualTime;
    },
    onUpdateCategory(id) {
      this.task.task_category_id = id;
    },
    onUpdateTag(id) {
      this.task.task_tag_id = id;
    },
    onUpdateRequested(id) {
      this.task.task_requested_user_id = id;
    },
    onUpdateRequester(id) {
      this.task.task_requester_user_id = id;
    },
    onUpdateRequesterUser(name) {
      this.task.task_requester_user_name = name;
    },
    openModal() {
      this.showContent = true;
    },
    closeModal() {
      this.showContent = false;
    },
    onCreateActualTime(actualTime) {
      this.task.task_actual_hours = actualTime;
    },
    onCreateTaskUrl(taskUrl) {
      this.task.task_url = taskUrl;
    },
    onChangeAssign(boolean) {
      this.task.task_assign_boolean = boolean;
    },

    // async getTask() {
    //   console.log(this.taskId)
    //   const requestConfig = {
    //     url: "/task",
    //     method: "GET",
    //     params: {
    //       id: this.taskId,
    //     },
    //   };
    //   myAxios(requestConfig)
    //     .then((res) => {
    //       this.task = res.data;
    //     })
    //     .finally(() => {});
    // },
    async deleltedTask() {
      if (!confirm("本当に削除しますか？")) return;
      const requestConfig = {
        url: "/task",
        method: "DELETE",
        data: {
          id: this.$route.query.taskId,
        },
      };
      myAxios(requestConfig).then(() => {
        this.$router.push("/main/task");
      });
    },
    async changeTask() {
      let isError = false;
      this.updateTaskNameError = "";
      this.requesterUserNameError = "";
      this.endDateError = "";
      // this.updateTaskDetailError = "";
      if (this.task.task_name === "") {
        isError = true;
        this.updateTaskNameError = "入力してください";
      }
      if (this.task.task_date_end < this.task.task_date_start) {
        isError = true;
        this.endDateError = "期限日は開始日よりも前に指定できません";
      }
      if (
        this.task.task_requester_user_id === -1 &&
        this.task.task_requester_user_name === ""
      ) {
        isError = true;
        this.requesterUserNameError = "入力してください";
      }
      // if (this.task.task_detail === "") {
      //   isError = true;
      //   this.updateTaskDetailError = "入力してください";
      // }
      if (isError) return;
      const requestConfig = {
        url: "/task",
        method: "PUT",
        data: {
          id: this.task.task_id,
          name: this.task.task_name,
          detail: this.task.task_detail,
          comment: this.task.task_comment,
          dateStart: this.task.task_date_start,
          dateEnd: this.task.task_date_end,
          predictedSeconds: this.task.task_predicted_hours,
          categoryId: this.task.task_category_id,
          tagId: this.task.task_tag_id,
          requestedUserId: this.task.task_requested_user_id,
          requesterUserId: this.task.task_requester_user_id,
          requesterUserName: this.task.task_requester_user_name,
          status: this.task.task_status,
          priority: this.task.task_priority,
          assignBoolean: this.task.task_assign_boolean,
        },
      };
      this.Loading = true;
      myAxios(requestConfig)
        .then(() => {
          this.$router.push("/main/mytask");
        })
        .catch(() => {
          alert("失敗しました！");
        })
        .finally(() => {
          this.Loading = false;
        });
    },
    async completeTask() {
      const requestConfig = {
        url: "/task_complete",
        method: "PUT",
        data: {
          id: this.task.task_id,
          actualSeconds: this.task.task_actual_hours,
          url: this.task.task_url,
          status: 5,
          priority: 3,
        },
      };
      this.Loading = true;
      myAxios(requestConfig)
        .then(() => {
          this.$router.push("/main/mytask");
        })
        .catch(() => {
          alert("失敗しました！");
        })
        .finally(() => {
          this.Loading = false;
        });
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("getUsers");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getTags");
    this.isLoading = false;
  },
};
</script>
<style  lang="scss" scoped>
#content {
  z-index: 2;
  width: 50%;
  padding: 1em;
  background: #fff;
}
#overlay {
  /*要素を重ねた時の順番*/
  z-index: 1;

  /*画面全体を覆う設定*/
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  /*画面の中央に要素を表示させる設定*/
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>